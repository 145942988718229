<template>
  <div class="grid grid-cols-2 gap-4">
    <!-- Questionnaire -->
    <div class="_indicator-category col-span-2">
      <div>
        <!-- Total Duration -->
        <div
          v-tooltip.auto="{
            content: 'Duração contando do primeiro usuário que iniciou ate o último usuário que finalizou.',
            delay: { show: 1000, hide: 100 }
          }"
          v-if="totalDuration"
          class="_indicator-block"
        >
          <span>
            {{ totalDuration }}
          </span>
          <label>
            {{ $t('duracao-total') }}
          </label>
        </div>
        <!-- Average Duration -->
        <div
          v-tooltip.auto="{
            content: 'Soma de todas as durações dividida pelo total de respostas.',
            delay: { show: 1000, hide: 100 }
          }"
          v-if="averageDuration"
          class="_indicator-block"
        >
          <span>
            {{ averageDuration }}
          </span>
          <label>
            {{ $t('duracao-media') }}
          </label>
        </div>
        <!-- Average Finish -->
        <div
          v-tooltip.auto="{
            content: 'Horário médio entre o primeiro usuário que iniciou e o último usuário que iniciou.',
            delay: { show: 1000, hide: 100 }
          }"
          v-if="averageStart"
          class="_indicator-block"
        >
          <span>
            {{ averageStart }}
          </span>
          <label>
            {{ $t('media-de-chegada') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    indicators: {
      type: Object,
      default: null
    },
  },
  computed: {
    averageDuration() {
      return this._.get(this.indicators, 'average_duration')
    },
    averageStart() {
      return this._.get(this.indicators, 'average_start')
    },
    totalDuration() {
      return this._.get(this.indicators, 'total_duration')
    },
  }
}
</script>

<style lang="scss" scoped>
  ._indicator-block {
    @apply flex;
    @apply flex-wrap;
    @apply items-stretch;

    // The element where the value lies.
    & > span {
      @apply font-bold;
      @apply w-full;
      @apply text-center;
      @apply block;
      @apply text-base;
    }

    // The element where the value's label is.
    & > label {
      @apply w-full;
      @apply text-center;
      @apply block;
      @apply text-base;
    }
  }

  ._indicator-category {
    // Every element of a category div.
    & > div {
      @apply w-full;
    }
    // Container of indicator-blocks on an indicator-category.
    & > :nth-child(1) {
      @apply flex;
      @apply flex-row;
      @apply gap-8;
      @apply justify-start;
    }
  }
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid grid-cols-2 gap-4" }, [
    _c("div", { staticClass: "_indicator-category col-span-2" }, [
      _c("div", [
        _vm.totalDuration
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.auto",
                    value: {
                      content:
                        "Duração contando do primeiro usuário que iniciou ate o último usuário que finalizou.",
                      delay: { show: 1000, hide: 100 },
                    },
                    expression:
                      "{\n          content: 'Duração contando do primeiro usuário que iniciou ate o último usuário que finalizou.',\n          delay: { show: 1000, hide: 100 }\n        }",
                    modifiers: { auto: true },
                  },
                ],
                staticClass: "_indicator-block",
              },
              [
                _c("span", [_vm._v(" " + _vm._s(_vm.totalDuration) + " ")]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("duracao-total")) + " "),
                ]),
              ]
            )
          : _vm._e(),
        _vm.averageDuration
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.auto",
                    value: {
                      content:
                        "Soma de todas as durações dividida pelo total de respostas.",
                      delay: { show: 1000, hide: 100 },
                    },
                    expression:
                      "{\n          content: 'Soma de todas as durações dividida pelo total de respostas.',\n          delay: { show: 1000, hide: 100 }\n        }",
                    modifiers: { auto: true },
                  },
                ],
                staticClass: "_indicator-block",
              },
              [
                _c("span", [_vm._v(" " + _vm._s(_vm.averageDuration) + " ")]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("duracao-media")) + " "),
                ]),
              ]
            )
          : _vm._e(),
        _vm.averageStart
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.auto",
                    value: {
                      content:
                        "Horário médio entre o primeiro usuário que iniciou e o último usuário que iniciou.",
                      delay: { show: 1000, hide: 100 },
                    },
                    expression:
                      "{\n          content: 'Horário médio entre o primeiro usuário que iniciou e o último usuário que iniciou.',\n          delay: { show: 1000, hide: 100 }\n        }",
                    modifiers: { auto: true },
                  },
                ],
                staticClass: "_indicator-block",
              },
              [
                _c("span", [_vm._v(" " + _vm._s(_vm.averageStart) + " ")]),
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("media-de-chegada")) + " "),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <vx-card>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <fieldset class="p-2 pt-0 border border-grey">
          <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
          <div class="flex items-center p-2 flex-wrap gap-4">
            <select-suggestion
              :max="20"
              :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
              @selected-model="updateGrid"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('Questionnaire')"
              v-model="questionnaire"
              :appendClearOption="true"
              ref="select_questionnaire"
              placeholderText="Digite o nome do questionário"
            />
          </div>
        </fieldset>
      </div>
      <div class="col-span-12" v-show="questionnaire">
        <Grid
          @grid-UPDATED="gridLoaded"
          ref="grid"
          :service="service"
          route_name="reports_questionnaire_time"
          route_grid_path="/questionnaire_time/grid"
          :fetchOnMounted="false"
          :hide_actions="true"
          :showExport="true"
          order_column="user_name"
          :exportFilename="`Relatório Duração - ${questionnaire && questionnaire.name}`"
        >
        <template v-slot:gridSubHeader>
          <div class="flex w-full justify-end pb-2">
            <questionnaire-time-report-indicators :indicators="indicators"/>
          </div>
        </template>
        </Grid>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import QuestionnaireTimeReportIndicators from './QuestionnaireTimeReportIndicators.vue'
export default {
  components: { SelectSuggestion, QuestionnaireTimeReportIndicators },

  data: () => ({
    service: null,
    indicators: null,
    questionnaire: null,
  }),

  watch: {
    questionnaire(val) {
      this.updateGrid()
    },
  },

  computed: {
    validFilters() {
      return this.questionnaire && this.questionnaire.id
    }
  },

  methods: {
    updateGrid() {
      if (this.validFilters) {
        this.grid().fillCustomFilters(
          [{key:'questionnaire_id', value: this.questionnaire.id}]
        )
        this.grid().fetchGridData(false)
      }
    },
    gridLoaded(response) {
      this.indicators = this._.get(response, 'indicators')
    },
    grid() {
      return this.$refs['grid']
    },
  },

  beforeMount() {
    this.service = ReportService.build(this.$vs)
  }
}
</script>